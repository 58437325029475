<template>
  <v-app id="inspire">
    <NavigationDrawer :items="menuItems" :drawer="drawer" />
    <AppBar :toggleDrawer="toggleDrawer" />
    <v-content>
      <router-view class="pr-0 pl-0 pt-0 pb-0" :key="$route.fullPath" />
      <v-snackbar
        color="red darken-3"
        :timeout="-1"
        v-model="isViewByRoleActive"
        class="view-as-outline button"
      >
        Currently viewing with other role(s)
        <v-btn class="mr-4 ml-8" to="/profile">Edit</v-btn>
      </v-snackbar>
    </v-content>
    <SearchOverlay />
  </v-app>
</template>

<script>
import { env } from '@/env';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import NavigationDrawer from '@/components/NavigationDrawer';
import AppBar from '@/components/AppBar';
import SearchOverlay, { inputRegex } from '@/components/SearchOverlay/SearchOverlay';
import { hasRoles } from '@/utils/auth';

export default {
  props: {
    source: String,
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.key === '/') {
        const element = event.target;
        if (element.id === 'search-bar' || !inputRegex.test(element.tagName)) {
          this.activateOverlay();
        }
      }
      if (event.key === 'Escape') {
        this.deactivateOverLay();
      }
    });
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      avatar: 'auth/avatar',
      name: 'auth/name',
      appVersion: 'appVersion',
      isViewByRoleActive: 'viewByRole/isViewByRoleActive',
    }),
    menuItems() {
      return [
        { icon: 'home', text: 'Streams', link: '/' },
        { icon: 'star', text: 'Watchlists', link: '/watchlists' },
        this.sportsMenu,
        this.casinoMenu,
        this.paymentsMenu,
        hasRoles(['ops:operator']) || hasRoles(['casino:operator'])
          ? { icon: 'speaker', text: 'Announcements', link: '/announcements' }
          : null,
        { icon: 'person', text: 'Profile', link: '/profile' },
        { icon: 'lock', text: 'Sign out', link: '/signout' },
      ].filter((item) => item !== null);
    },
    sportsMenu() {
      const menu = {
        heading: 'Sports',
        icon: 'sports_volleyball',
        children: [
          hasRoles(['sports:user', 'sports:operator'])
            ? {
                icon: 'show_chart',
                text: 'Month to Date',
                link: `${env.redashUri}/dashboards/76-fd-dashboards---monthly-summary`,
              }
            : null,
          hasRoles(['sports:user', 'sports:operator'])
            ? {
                icon: 'show_chart',
                text: 'Today',
                link: `${env.redashUri}/dashboards/77-fd-dashboards---today`,
              }
            : null,
          hasRoles(['sports:user', 'sports:operator'])
            ? {
                icon: 'show_chart',
                text: 'EV per Event Today',
                link: `${env.redashUri}/dashboards/79-fd-dashboards---per-event-ev?p_date=d_now`,
              }
            : null,
          hasRoles(['sports:operator'])
            ? { icon: 'event_note', text: 'Top events', link: '/top-events' }
            : null,
          hasRoles(['sports:operator'])
            ? { icon: 'people', text: 'Top players', link: '/top-players' }
            : null,
          hasRoles(['sports:operator', 'sports:user'])
            ? { icon: 'sports_soccer', text: 'All sports', link: '/sports/soccer' }
            : null,
          hasRoles(['sports:operator'])
            ? {
                icon: 'perm_data_setting',
                text: 'Providers Status',
                link: '/providers-status',
              }
            : null,
          hasRoles(['sports:operator']) && env.feature.enableGrading
            ? {
                icon: 'event_note',
                text: 'Pending Events',
                link: '/pending-events',
              }
            : null,
          hasRoles(['sports:operator', 'dataentry:operator', 'localization:operator'])
            ? {
                icon: 'font_download',
                text: 'Competition Translations',
                link: '/translations/competition',
              }
            : null,
          hasRoles(['sports:operator', 'dataentry:operator', 'localization:operator'])
            ? {
                icon: 'font_download',
                text: 'Competitor Translations',
                link: '/translations/competitor',
              }
            : null,
          hasRoles(['sports:operator', 'dataentry:operator', 'localization:operator'])
            ? {
                icon: 'font_download',
                text: 'Outright Translations',
                link: '/translations/outright',
              }
            : null,
          hasRoles(['sports:operator', 'dataentry:operator']) &&
          env.enableCompetitionPriorities
            ? {
                icon: 'people',
                text: 'Competitions priorities',
                link: '/competitions/priorities',
              }
            : null,
          hasRoles(['sports:operator'])
            ? {
                icon: 'people',
                text: 'Liability settings',
                link: '/liability_settings',
              }
            : null,
          hasRoles(['sports:operator', 'sports:user'])
            ? {
                icon: 'loyalty',
                text: 'Loyalty schedules',
                link: '/loyalty_schedules',
              }
            : null,
          hasRoles(['sports:operator'])
            ? {
                icon: 'event',
                text: 'Event Creator',
                link: '/events/creator',
              }
            : null,
          hasRoles(['sports:operator', 'sports:user']) && env.feature.enableEventsMatching
            ? {
                icon: 'event',
                text: 'Events Matching',
                link: '/events/matching',
              }
            : null,
          hasRoles(['sports:operator', 'sports:user']) && env.feature.enableCSGrading
            ? {
                icon: 'support_agent',
                text: 'CS Grading',
                link: '/cs-grading',
              }
            : null,
        ].filter((item) => item !== null),
      };

      if (menu.children.length === 0) {
        return null;
      }

      return menu;
    },
    casinoMenu() {
      const menu = {
        heading: 'Casino',
        icon: 'casino',
        children: [
          // Casino menu child example (commented out)
          // hasRoles(['casino:operator', 'casino:user'])
          //   ? {
          //       icon: '',
          //       text: 'Casino Example',
          //       link: '/casino-example',
          //     }
          //   : null,
          hasRoles(['casino:operator', 'casino:user'])
            ? {
                icon: 'format_list_numbered',
                text: 'Loyalty Tiers',
                link: '/casino-loyalty-tiers',
              }
            : null,
        ].filter((item) => item !== null),
      };

      if (menu.children.length === 0) {
        return null;
      }

      return menu;
    },
    paymentsMenu() {
      const menu = {
        heading: 'Payments',
        icon: 'payments',
        children: [
          hasRoles(['cs:operator', 'cs:user', 'payments:operator', 'payments:user'])
            ? {
                icon: 'sync',
                text: 'Crypto Deposit Resync',
                link: '/crypto-deposit-resync',
              }
            : null,
          hasRoles(['payments:operator', 'payments:user'])
            ? {
                icon: 'construction',
                text: 'Failed Withdrawals',
                link: '/failed-withdrawals-ops',
              }
            : null,
            {
                icon: 'monetization_on',
                text: 'Monthly Withdrawal Fees',
                link: `${env.redashUri}/queries/861?fullscreen&p_date_range=d_this_month#1205`,
            }, {
                icon: 'account_balance_wallet',
                text: 'Hot Wallet Balances',
                link: `${env.redashUri}/queries/278?fullscreen`,
            }, {
                icon: 'schedule_send',
                text: 'Withdrawals Stuck at Sending',
                link: `${env.redashUri}/public/dashboards/n8SrmDrtkpw2b6prQ0z1Ft02Yxyz9Q7LYWz6IfB9?fullscreen`,
            }, {
                icon: 'approval',
                text: 'Withdrawals for FB Approval',
                link: `${env.redashUri}/dashboards/102-withdrawals-pending-approval-in-fireblocks?fullscreen`,
            }, {
                icon: 'currency_bitcoin',
                text: 'Crypto Transactions',
                link: `${env.redashUri}/dashboards/95-finance---crypto-transactions?fullscreen&p_date_range=d_this_month`,
            }, {
                icon: 'attach_money',
                text: 'Cash Transactions',
                link: `${env.redashUri}/dashboards/97-finance---cash-transactions?fullscreen&p_date_range=d_this_month`,
            },
        ].filter((item) => item !== null),
      };

      if (menu.children.length === 0) {
        return null;
      }

      return menu;
    },
  },
  components: {
    NavigationDrawer,
    AppBar,
    SearchOverlay,
  },
  data: () => ({
    drawer: null,
    isOverlayActive: false,
  }),
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    activateOverlay() {
      this.isOverlayActive = !this.isOverlayActive;
    },
    deactivateOverLay() {
      this.isOverlayActive = false;
    },
    ...mapActions({
      toggleViewByRole: 'viewByRole/toggleViewByRole',
    }),
  },
};
</script>

<style lang="less">
.overlay {
  min-width: 500px;
}
</style>
